<script>

export default {
  name: 'NotFound',
}

</script>

<template>

  <div class="error">
    <div class="error__title">Не удалось загрузить данные</div>
    <div class="error__reload" @click="$emit('reload')">пробовать снова</div>
  </div>

</template>

<style scoped lang="scss">

.error {
  position: absolute;
  left: 50%;
  top: 500px;
  transform: translate(-50%, -50%);
  font-size: 20px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
  }

  &__reload {
    color: #6565ff;
    cursor: pointer;
  }
}

</style>