<script>

export default {
  name: 'TheApprove',

  computed: {
    dateNow() {
      const now = new Date() 
      const date = now.getDate() 
      const month = now.getMonth() + 1 
 
      return `${date < 10 ? '0' + date : date}.${month < 10 ? '0' + month : month}.${now.getFullYear()}`
    }
  }
}

</script>

<template>

  <div class="approve">
    <!-- <div class="approve__description mfo-count">
      МФО с наибольшим количеством <br> одобрений на {{ dateNow }}
    </div> -->
    
    <img src="../assets/like.svg">

    <div class="approve__container">
      <div class="approve__title">Вам одобрили займ!</div>
      
      <div class="approve__description">
        Оформите <span>минимум 3&nbsp;заявки</span>
        <br>
        для <span>100%</span>  получения займа
      </div>
    </div>
  </div>

</template>
  
<style scoped lang="scss">

.approve {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 364px;
  width: 100%;
  padding-top: 12px;
  background-image: url("../assets/money-bg.png");
  background-color: rgb(58 56 79);

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    width: 280px;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;

  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;

    span {
      font-weight: 800;
    }
  }

}

img {
  width: 64px;
  height: 64px;
  margin-block: 24px;
}

.mfo-count br {
  display: none;
}

@media (max-width: 440px) {
  .mfo-count {
    text-align: center;
    br {
      display: block;
    }
  }

  img {
    margin-block: 16px;
  }
}

</style>
