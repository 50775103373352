<script>

export default {
  name: 'TheBanner',

  props: {
    submittedCount: { type: Number, required: true },
  },

  computed: {
    buttonTitle() {
      return this.submittedCount > 2 ? 'Получить' : `Выбрано ${this.submittedCount} из 3`
    }
  },
}

</script>

<template>

  <div class="banner">
    <div class="banner__container">

      <div class="banner__progress">
        <div :style="submittedCount && 'background: #10C698'"></div>
        <div :style="submittedCount > 1 && 'background: #10C698'"></div>
        <div :style="submittedCount > 2 && 'background: #10C698'"></div>
      </div>

      <div class="banner__submitted">
        Отправлено {{  submittedCount  }} из 3 заявок
      </div>

      <div class="banner__description">
        Для получения персонального предложения со&nbsp;100% одобрением
        <br>
        отправьте заявку в&nbsp;3&nbsp;компании
      </div>

      <button
        v-if="submittedCount > 2"
        :style="submittedCount > 2 ? 'background: #10C698; color: #FFFFFF;' : 'pointer-events: none;'"
        @click="submittedCount > 2 && $emit('showPopularOffers')">
        {{ buttonTitle }}
      </button>
    </div>
  </div>
  
</template>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.banner {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 16px 0 12px;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 32px #73869D;
  border-radius: 16px 16px 0px 0px;

  &__container {
    text-align: center;
  }

  &__submitted {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  &__progress{
    margin-bottom: 8px;
    display: flex;
    justify-content: center;

    div {
      width: 110px;
      height: 8px;
      margin-inline: 4px;
      background: #EAEAEA;
      border-radius: 4px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }

  button {
    width: 343px;
    height: 48px;
    margin-top: 8px;
    background: #EAEAEA;
    box-shadow: 0px 10px 20px rgba(59, 179, 142, 0.25);
    border-radius: 8px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #9BA8B8;
  }
}

@media (max-width: 470px) {
  br {
      display: none;
  }
}
@media (max-width: 374px) {
  .banner {
      &__progress{
        div {
          width: 92px;
        }
      }

    button {
      width: 300px !important;
    }
  }
}

</style>
