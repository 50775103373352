<script>

export default {
  name: 'TheLoader',
}

</script>

<template>

  <div>
    <div class="loader"> 
      <span></span> 
      <span></span> 
      <span></span> 
    </div> 
  </div>

</template>

<style scoped lang="scss">

div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader { 
  position: relative; 
  top: calc(50vh - 340px);
  width: 100px; 
  height: 100px; 
  background-color: #e7e7e7;
  border-bottom: 4px solid #16ddaf75; 
  border-radius: 50%; 
  animation: animate 3s linear infinite; 
  & ::before { 
    content: ''; 
    position: absolute; 
    top: 10px; 
    left: 10px; 
    bottom: 10px; 
    right: 10px; 
    border-bottom: 4px solid #16ddafb3; 
    border-radius: 50%; 
    animation: animate 2s linear infinite; 
  }
  &::after { 
    content: ''; 
    position: absolute; 
    top: 22px; 
    left: 22px; 
    bottom: 22px; 
    right: 22px; 
    border-bottom: 4px solid #16ddad; 
    border-radius: 50%; 
    animation: animate 1s linear infinite; 
  } 
}

@keyframes animate { 
  0% 
  { 
      transform: rotate(0deg); /* X,Y */ 
  } 
  100% 
  { 
      transform: rotate(360deg); 
  } 
}

</style>