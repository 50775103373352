<script>

import Card from '@/components/Card'

export default {
  name: 'PopularOffers',

  components: {
    Card,
  },

  props: {
    show: { type: Boolean, required: true },
    list: { type: Array, required: true },
  },
}

</script>

<template>

  <div v-if="show" class="popular-offers">
    <div class="popular__container">
      <img
        class="popular-offers__close"
        src="../assets/close.svg" alt="зыкрыть"
        @click="$emit('close')"
      >

      <div class="popular-offers__title">
        Популярные предложения
      </div>

      <div class="popular-offers__description">
        Мы&nbsp;подготовили для вас самые выгодные
        <br>
        микрозаймы, чтобы вы&nbsp;не&nbsp;тратили
        <br>
        время на&nbsp;поиск
      </div>
    </div>

    <div class="popular-offers__body">
      <div class="popular-offers__list">
        <Card
          v-for="card in list"
          :key="card.id"
          :card="card"
          @onClick="link => $emit('onClick', link)">
        </Card>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

.popular-offers {
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px -8px 32px -8px #73869D;
  color: #000000;
  text-align: center;
  background: #FFFFFF;

  &__container {
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 21px;
    right: 13px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__body {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 12px;
    padding-block: 8px;
    background-color: #e7e7e7;
  }

  &__list {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}

@media (max-height: 425px) and (orientation: landscape) {
  .popular-offers {
    height: 100%;
    overflow-y: scroll;
  }
}

</style>
