<script>

import Approve from '@/components/Approve'
import Card from '@/components/Card'
import NotFound from '@/components/NotFound'
import Loader from '@/components/Loader'
import Banner from '@/components/Banner'
import PopularOffers from '@/components/PopularOffers'

export default {
  name: 'TheHome',

  components: {
    Approve,
    Card,
    NotFound,
    Loader,
    Banner,
    PopularOffers,
  },

  data: () => ({
    list: [],
    loading: false,
    showPopularOffers: false,
    submittedCount: 0,
  }),

  created() {
    this.getCardsList()
  },

  methods: {
    getCardsList() {
      this.loading = true

      fetch(`${process.env.VUE_APP_SERVER_URL}/public-api/projects/${process.env.VUE_APP_PROJECT_ID}`)
        .then(response => response.json())
        .then(({ successful, result }) => {
          if (successful) {
            this.list = result.map(({ offer, is_featured }) => ({ ...offer, is_featured, is_clicked: false }))
          }
        })
        .finally(() => this.loading = false)
    },

    update(link) {
      this.list.forEach((card, index) => {
        if (card.link === link) {
          if (!this.list[index].is_clicked) {
            this.submittedCount++
          }
          this.list[index].is_clicked = true

          const clicked = this.list.splice(index, 1)[0]

          this.list.push(clicked)
        }
      })

      window.open(this.getCheckedLinkByMarker(link), '_blank')

      this.showPopularOffers = true
    },

    getCheckedLinkByMarker(link) {
      if (!link.includes('?')) { return link }

      let checkedLink = link.split(link.includes('sub1=') ? '&' : '?')[0]

      if (window.location.search) {
        const paramsUrlObj = {}
        new URL(window.location).searchParams.forEach((val, key) => { paramsUrlObj[key] = val })

        const paramsLinkObj = {}
        new URL(link).searchParams.forEach((val, key) => { paramsLinkObj[key] = val })

        Object.keys(paramsLinkObj).forEach(key => {
          const marker = paramsUrlObj[paramsLinkObj[key]]

          if (marker) {
            checkedLink += `${checkedLink.includes('?') ? '&' : '?'}${key}=${marker}`
          }
        })
      }

      return checkedLink
    }
  }
}

</script>

<template>

  <div class="offerswitcher">
    <Approve />
    
    <Loader v-if="loading" />
    <div v-else class="main">
      <div class="overlay" v-if="showPopularOffers"></div>

      <div v-if="list.length" class="list">

        <Card
          v-for="card in list"
          :key="card.id"
          :card="card"
          @onClick="update">
        </Card>

        <div class="info">
          Сайт оказывает услуги по&nbsp;подбору кредитных продуктов для клиентов, а&nbsp;именно предлагает клиенту список предложений кредитных учреждений, некредитных финансовых организаций, в&nbsp;которые клиент может обратиться с&nbsp;целью оформления заявки на&nbsp;кредитный продукт. Проект не&nbsp;является банком или кредитором, не&nbsp;относится к&nbsp;финансовым учреждениям и&nbsp;не&nbsp;несёт ответственности за&nbsp;последствия любых заключенных договоров кредитования или условия по&nbsp;ним. Минимальная процентная ставка у&nbsp;некоторых партнеров составляет&nbsp;0%.
        </div>
      </div>

      <NotFound v-else @reload="getCardsList" />
    </div>

    <Banner
      v-if="list.length"
      :submittedCount="submittedCount"
      @showPopularOffers="showPopularOffers = true"
    />

    <PopularOffers
      :show="showPopularOffers" 
      :list="[list[0], list[1]]"
      @close="showPopularOffers = false"
      @onClick="update"
    />
  </div>

</template>

<style>

body {
  background-color: #e7e7e7;
}

.offerswitcher {
  font-family: 'Open Sans', sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 200px
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: #0B161F;
  opacity: 0.5;
}

.list {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: -108.4px;
  box-sizing: border-box;
}

.info {
  margin: 48px 32px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #73869D;
  max-width: 1173px;
}

</style>
