<script>

export default {
  name: 'TheCard',

  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}

</script>

<template>

  <div class="card" @click="$emit('onClick', card.link)">
    <!-- <div class="card__img-container" :style="card.is_featured && 'height: 40px;'"> -->
    <div class="card__img-container">
      <img :src="card.logo" :alt="card.partner">
    </div>

    <div class="card__hr"></div>

    <!-- <div v-if="card.is_featured" class="card__first-free">
      Первый займ <span>бесплатно!</span>
    </div>
    <div v-else class="card__hr"></div> -->

    <div class="card__amount">
      <div>
        <div class="title">Максимальная сумма:</div>
        <div class="amount">{{ card.header }} <img src="../assets/rubl.svg" alt="rubl"></div>
      </div>

      <div>
        <div class="title">Ставка от:</div>
        <div class="percent" :style="card.is_featured && 'color: #FF3B30;'">{{ card.is_featured ? 0 : 0.8 }}%</div>
      </div>
    </div>

    <div class="card__sub-header">{{ card.sub_header }}</div>

    <button :class="card.is_clicked && 'clicked'">
      Получить <span class="hide">деньги</span>
    </button>
  </div>

</template>

<style scoped lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.card {
  width: 174px;
  padding: 12px;
  margin: 4px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(115, 134, 157, 0.5);
  border-radius: 12px;
  text-align: center;
  cursor: pointer;

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    margin-top: -8px;
  }

  &__img-container img {
    height: 40px;
    padding-block: 8px;
  }

  &__first-free {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    margin-inline: -12px;
    color: #fff;
    background: linear-gradient(180deg, #FF801A 0%, #FF9A00 100%);
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
  }
  &__first-free span {
    text-transform: uppercase;
    font-weight: 900;
    margin-left: 4px;
  }

  &__hr {
    border-bottom: 1px solid #D1D1D6;
    margin: 0 -12px 0px;
  }

  &__amount {
    padding-top: 16px;
    text-align: center;
  }
  &__amount .title {
    margin-bottom: 3px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #828282;
  }
  &__amount .amount {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 32px;
    line-height: 24px;
    color: #1C1C1E;
  }
  &__amount .amount img {
    height: 22px;
    color: #636366;
  }
  &__amount .percent {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #2F80ED;
  }

  &__sub-header {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #828282;
  }

  button {
    width: 100%;
    height: 40px;
    border: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-shadow: 0px 0.757143px 2.27143px #0EA781;
    color: #FFFFFF;
    background: #10C698;
    box-shadow: 0px 7.57143px 15.1429px rgba(59, 179, 142, 0.25);
    border-radius: 6px;
  }

  .clicked {
    background: #d5dbe2;
    color: #000000;
    text-shadow: none;
  }
}

@media (max-width: 374px) {
  .card {
    width: calc(50% - 13px);
    padding: 8px;

    &__hr {
      margin-inline: -8px;
    }
  }
  .hide {
    display: none;
  }
}

</style>
  